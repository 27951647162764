/* CreateClass.css */

/* General page styling */
.create-class-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    background-color: #f7f7f7;
    font-family: 'Arial', sans-serif;
    min-height: 100vh;
  }
  
  .create-class-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 40px;
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
  }
    
  /* Form styling */
  .create-class-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .class-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
    color: #333333;
    display: block;
  }
  
 /* General input field styling with unique class */
.cc-input-field {
  width: 100%;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
  color: #555555;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
}

.cc-input-field:focus {
  border-color: #007bff;
  outline: none;
}
  
  textarea {
    height: 100px;
    resize: vertical;
  }
  
  .select {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    font-size: 14px;
  }
  
  /* Submit button */
  .submit-button {
    background-color: #007bff;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    width: 100%;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
