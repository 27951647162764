.edit-profile-container {
    background: linear-gradient(135deg, rgba(115, 0, 222, 0.8), rgba(172, 72, 232, 0.9));
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

h1 {
    font-size: 2em;
    margin-bottom: 20px;
    color: white;
}

.edit-profile-form {
    display: flex;
    flex-direction: column;
}

.edit-profile-form label {
    font-size: 1.2em;
    color: white;
    text-align: left;
    margin: 10px 0 5px;
}

.edit-profile-form input,
.edit-profile-form textarea {
    padding: 10px;
    font-size: 1em;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    width: 100%;
}

.edit-profile-form textarea {
    height: 100px;
}

button {
    padding: 12px 20px;
    font-size: 1.1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

button[type="submit"] {
    background-color: #6a1b9a;
    color: white;
}

.cancel-button {
    background-color: #b3b3b3;
    color: white;
}

.error-message {
    color: red;
    margin-bottom: 20px;
}

.success-message {
    color: green;
    margin-bottom: 20px;
}
