/* Styling for the Profile Page */
.profile-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(135deg, rgba(115, 0, 222, 0.8), rgba(172, 72, 232, 0.9)), 
                url('./foggy-birds.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat;
    background-size: auto;
    background-blend-mode: overlay;
}

.profile-container {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white;
    padding: 50px;
    border-radius: 25px;
    width: 70rem;
    margin: 50px auto; /* Added more margin for a balanced appearance */
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5); /* Larger shadow for a more prominent effect */
    z-index: 2; /* Ensure it is above the background */
    text-align: center;
}

.profile-container h1 {
    font-size: 4rem;
    margin-bottom: 20px;
    color: #ac48e8;
    text-decoration: underline;
}

.profile-info p {
    font-size: 3rem;
    margin-bottom: 15px;
}

.error-message {
    color: red;
    font-size: 1.2rem;
}

.logout-button {
    background-color: #6c63ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 3rem;
    transition: background-color 0.3s ease;
}

.logout-button:hover {
    background-color: #5846bf;
}

