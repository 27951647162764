.yogaSimulation {
    width: 100vw;
    height: 100vh;
    position: relative;
    background: #000000; /* Start with a black screen */
    overflow: hidden;
    transition: background-color 30s ease-in-out;
  }

  .yogaSimulation::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 200vw; /* Make the background larger to allow movement */
    height: 200vw; 
    background-image: url('stars.png'); /* Path to your star PNG */
    background-size: cover;
    background-position: center;
    background-repeat: repeat;
    opacity: 0.3; /* Adjust the opacity for subtle effect */
    z-index: 0; /* Ensure stars stay behind the shapes */
    pointer-events: none; /* Let buttons and shapes stay clickable */
    animation: moveStars 30s linear infinite; /* Slow drifting animation */
}

/* Keyframes for moving the star background */
@keyframes moveStars {
    0%, 100% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(-25px, -25px); /* Slight movement over time */
    }
    50% {
        transform: translate(-50px, -40px); /* Slight movement over time */
    }
    75% {
        transform: translate(-25px, -20px); /* Slight movement over time */
    }
}

  /* Shared shape styles */
  .animatedShape {
    width: 120px;
    height: 120px;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
    z-index: 1;
    transition: all 10s ease-in-out; /* Smooth transitions */
  }

    @keyframes leaveTrail {
    0% {
        filter: drop-shadow(0 0 10px rgba(255, 255, 255, 0.5)); /* Initial small shadow */
    }
    25% {
        filter: drop-shadow(0 0 20px rgba(255, 255, 255, 0.4)); /* Larger shadow */
    }
    50% {
        filter: drop-shadow(0 0 30px rgba(255, 255, 255, 0.3)); /* Larger shadow, fading */
    }
    75% {
        filter: drop-shadow(0 0 40px rgba(255, 255, 255, 0.2)); /* Largest shadow, mostly faded */
    }
    100% {
        filter: drop-shadow(0 0 50px rgba(255, 255, 255, 0.1)); /* Very subtle final shadow */
    }
    }
  
  /* Zen-like Shape 1: Circle */
  .shape0 {
    clip-path: circle(50%);
    background-color: rgba(204, 102, 255, 0.8); /* Light purple */
    animation: morphShape0 40s infinite ease-in-out, scaleShape 25s infinite ease-in-out, leaveTrail 60s infinite;
  }
  
  /* Zen-like Shape 2: Spiral-like abstract */
  .shape1 {
    clip-path: polygon(50% 0%, 80% 40%, 100% 50%, 80% 60%, 50% 100%, 20% 60%, 0% 50%, 20% 40%);
    background-color: rgba(255, 204, 102, 0.8); /* Light orange */
    animation: morphShape1 45s infinite ease-in-out, scaleShape 30s infinite ease-in-out;
  }
  
  /* Zen-like Shape 3: Mandala-inspired */
  .shape2 {
    clip-path: polygon(50% 0%, 70% 40%, 100% 50%, 70% 60%, 50% 100%, 30% 60%, 0% 50%, 30% 40%);
    background-color: rgba(102, 255, 204, 0.8); /* Light green */
    animation: morphShape2 50s infinite ease-in-out, scaleShape 35s infinite ease-in-out;
  }
  
  /* Zen-like Shape 4: Lotus-inspired */
.shape3 {
    clip-path: polygon(50% 0%, 60% 20%, 80% 30%, 90% 50%, 80% 70%, 60% 80%, 50% 100%, 40% 80%, 20% 70%, 10% 50%, 20% 30%, 40% 20%);
    background-color: rgba(255, 204, 153, 0.8); /* Light peach */
    animation: morphShape3 55s infinite ease-in-out, scaleShape 40s infinite ease-in-out;
  }

  /* Zen-like Shape 5: Crescent-inspired */
.shape4 {
    clip-path: polygon(50% 0%, 70% 20%, 85% 40%, 85% 60%, 70% 80%, 50% 100%, 30% 80%, 15% 60%, 15% 40%, 30% 20%);
    background-color: rgba(153, 204, 255, 0.8); /* Light blue */
    animation: morphShape4 60s infinite ease-in-out, scaleShape 45s infinite ease-in-out;
  }
  
/* Combined keyframes for Shape 0 */
@keyframes animateShape0 {
    0% {
      clip-path: circle(50%);
      transform: scale(0.001) rotate(0deg);
    }
    40% {
      clip-path: circle(90%);
      transform: scale(1.4) rotate(120deg); /* Larger scale, partial rotation */
    }
    60% {
      clip-path: circle(70%);
      transform: scale(0.8) rotate(240deg); /* Smaller scale, more rotation */
    }
    100% {
      clip-path: circle(50%);
      transform: scale(.001) rotate(360deg); /* Back to original shape and full rotation */
    }
  }
  
  /* Combined keyframes for Shape 1 */
  @keyframes animateShape1 {
    0% {
      clip-path: polygon(50% 0%, 80% 40%, 100% 50%, 80% 60%, 50% 100%, 20% 60%, 0% 50%, 20% 40%);
      transform: scale(.5) rotate(0deg);
    }
    30% {
      clip-path: polygon(70% 30%, 90% 50%, 70% 70%, 50% 100%, 20% 70%, 10% 50%, 30% 30%);
      transform: scale(4.5) rotate(90deg); /* Larger scale, quarter rotation */
    }
    50% {
      clip-path: polygon(40% 10%, 70% 30%, 90% 50%, 70% 70%, 40% 90%, 20% 70%, 10% 50%, 30% 40%);
      transform: scale(1.0) rotate(180deg); /* Midway scale, half rotation */
    }
    100% {
      clip-path: polygon(50% 0%, 80% 40%, 100% 50%, 80% 60%, 50% 100%, 20% 60%, 0% 50%, 20% 40%);
      transform: scale(.5) rotate(360deg); /* Back to original, full rotation */
    }
  }
  
  @keyframes animateShape2 {
    0% {
      clip-path: polygon(50% 10%, 85% 30%, 100% 60%, 75% 85%, 50% 100%, 25% 85%, 0% 60%, 15% 30%);
      transform: scale(1) rotate(0deg);
    }
    25% {
      clip-path: polygon(55% 15%, 90% 45%, 80% 70%, 60% 95%, 40% 95%, 20% 70%, 10% 45%, 45% 15%);
      transform: scale(1.4) rotate(90deg); /* Rounded star-like shape, smoother rotation */
    }
    50% {
      clip-path: polygon(60% 20%, 85% 55%, 70% 85%, 50% 100%, 30% 85%, 15% 55%, 30% 20%);
      transform: scale(1.8) rotate(180deg); /* Expands into a rounded heart-like shape */
    }
    75% {
      clip-path: polygon(65% 25%, 90% 50%, 70% 85%, 50% 100%, 30% 85%, 10% 50%, 30% 25%);
      transform: scale(1.2) rotate(270deg); /* Shrinks into a rounded leaf-like shape */
    }
    100% {
      clip-path: polygon(50% 10%, 85% 30%, 100% 60%, 75% 85%, 50% 100%, 25% 85%, 0% 60%, 15% 30%);
      transform: scale(1) rotate(360deg); /* Back to original shape and full rotation */
    }
  }

  /* Combined keyframes for Shape 3 */
  @keyframes animateShape3 {
    0%, 100% {
      /* A person shape (head, arms, body, legs) */
      clip-path: polygon(
        50% 0%,   /* Head */
        55% 10%,  /* Neck */
        70% 30%,  /* Right shoulder */
        80% 45%,  /* Right hand */
        70% 50%,  /* Right waist */
        75% 70%,  /* Right leg */
        60% 100%, /* Right foot */
        50% 90%,  /* Middle */
        40% 100%, /* Left foot */
        25% 70%,  /* Left leg */
        30% 50%,  /* Left waist */
        20% 45%,  /* Left hand */
        30% 30%,  /* Left shoulder */
        45% 10%   /* Neck */
      );
      transform: scale(0.8) rotate(0deg); /* Initial size and position */
    }
    25% {
      /* Raise the right hand, shift the legs */
      clip-path: polygon(
        50% 0%,   /* Head */
        55% 10%,  /* Neck */
        70% 30%,  /* Right shoulder */
        85% 25%,  /* Right hand raised */
        70% 50%,  /* Right waist */
        75% 70%,  /* Right leg */
        60% 100%, /* Right foot */
        50% 90%,  /* Middle */
        40% 100%, /* Left foot */
        20% 80%,  /* Left leg extended */
        30% 50%,  /* Left waist */
        20% 45%,  /* Left hand */
        30% 30%,  /* Left shoulder */
        45% 10%   /* Neck */
      );
      transform: scale(1.1) rotate(45deg); /* Larger scale, rotation */
    }
    50% {
      /* Both hands raised and legs spread */
      clip-path: polygon(
        50% 0%,   /* Head */
        55% 10%,  /* Neck */
        80% 20%,  /* Right shoulder */
        90% 10%,  /* Right hand raised high */
        80% 50%,  /* Right waist */
        85% 70%,  /* Right leg */
        70% 100%, /* Right foot */
        50% 85%,  /* Middle */
        30% 100%, /* Left foot */
        15% 70%,  /* Left leg */
        20% 50%,  /* Left waist */
        10% 10%,  /* Left hand raised high */
        20% 20%,  /* Left shoulder */
        45% 10%   /* Neck */
      );
      transform: scale(0.9) rotate(180deg); /* Smaller scale, half rotation */
    }
    75% {
      /* Lean forward, arms lower, legs crossed */
      clip-path: polygon(
        50% 0%,   /* Head */
        55% 10%,  /* Neck */
        70% 30%,  /* Right shoulder */
        80% 45%,  /* Right hand */
        70% 50%,  /* Right waist */
        60% 80%,  /* Right leg */
        70% 100%, /* Right foot */
        50% 90%,  /* Middle */
        35% 100%, /* Left foot */
        40% 70%,  /* Left leg */
        30% 50%,  /* Left waist */
        20% 45%,  /* Left hand */
        30% 30%,  /* Left shoulder */
        45% 10%   /* Neck */
      );
      transform: scale(1.2) rotate(270deg); /* Larger scale, partial rotation */
    }
  }
  /* Combined keyframes for Shape 4 */
  @keyframes animateShape4 {
    0% {
      clip-path: polygon(50% 0%, 70% 20%, 85% 40%, 85% 60%, 70% 80%, 50% 100%, 30% 80%, 15% 60%);
      transform: scale(0.001) rotate(0deg);
    }
    40% {
      clip-path: polygon(60% 0%, 80% 30%, 90% 50%, 80% 70%, 60% 100%, 30% 70%);
      transform: scale(2) rotate(120deg); /* Larger scale, partial rotation */
    }
    60% {
      clip-path: polygon(70% 10%, 90% 30%, 80% 50%, 60% 80%, 50% 100%);
      transform: scale(0.6) rotate(240deg); /* Smaller scale, more rotation */
    }
    100% {
      clip-path: polygon(50% 0%, 70% 20%, 85% 40%, 85% 60%, 70% 80%, 50% 100%, 30% 80%, 15% 60%);
      transform: scale(0.001) rotate(360deg); /* Full rotation */
    }
  }
  
  /* Combined keyframes for Shape 5 */
  @keyframes animateShape5 {
    0% {
      clip-path: polygon(50% 0%, 80% 30%, 100% 60%, 80% 90%, 50% 100%, 30% 90%, 0% 60%);
      transform: scale(0.001) rotate(0deg);
    }
    30% {
      clip-path: polygon(70% 20%, 90% 40%, 100% 60%, 80% 90%, 50% 100%, 20% 80%);
      transform: scale(1.8) rotate(90deg); /* Larger scale, partial rotation */
    }
    50% {
      clip-path: polygon(60% 10%, 80% 40%, 100% 60%, 70% 80%, 40% 100%);
      transform: scale(0.9) rotate(180deg); /* Smaller scale, half rotation */
    }
    100% {
      clip-path: polygon(50% 0%, 80% 30%, 100% 60%, 80% 90%, 50% 100%, 30% 90%, 0% 60%);
      transform: scale(.001) rotate(360deg); /* Full rotation */
    }
  }
  
  /* Combined keyframes for Shape 6 */
  @keyframes animateShape6 {
    0% {
      clip-path: polygon(50% 0%, 70% 30%, 90% 50%, 70% 80%, 50% 100%, 30% 80%);
      transform: scale(0.2) rotate(0deg);
    }
    35% {
      clip-path: polygon(60% 10%, 80% 30%, 100% 50%, 70% 90%, 40% 100%, 20% 80%);
      transform: scale(1.7) rotate(90deg); /* Larger scale, partial rotation */
    }
    55% {
      clip-path: polygon(50% 0%, 70% 40%, 100% 60%, 70% 80%, 40% 100%);
      transform: scale(1.1) rotate(180deg); /* Midway scale, half rotation */
    }
    100% {
      clip-path: polygon(50% 0%, 70% 30%, 90% 50%, 70% 80%, 50% 100%, 30% 80%);
      transform: scale(0.2) rotate(360deg); /* Full rotation */
    }
  }
  
  /* Combined keyframes for Shape 7 */
  @keyframes animateShape7 {
    0% {
      clip-path: polygon(50% 0%, 60% 40%, 80% 60%, 60% 80%, 50% 100%, 40% 80%);
      transform: scale(1.1) rotate(0deg);
    }
    30% {
      clip-path: polygon(70% 10%, 80% 40%, 100% 60%, 70% 90%, 40% 100%);
      transform: scale(2) rotate(120deg); /* Larger scale, partial rotation */
    }
    50% {
      clip-path: polygon(60% 20%, 80% 40%, 100% 60%, 70% 80%, 30% 100%);
      transform: scale(1.3) rotate(180deg); /* Midway scale, half rotation */
    }
    100% {
      clip-path: polygon(50% 0%, 60% 40%, 80% 60%, 60% 80%, 50% 100%, 40% 80%);
      transform: scale(1.1) rotate(360deg); /* Full rotation */
    }
  }
  
  /* Combined keyframes for Shape 8 */
  @keyframes animateShape8 {
    0% {
      clip-path: polygon(50% 0%, 70% 20%, 90% 40%, 70% 60%, 50% 100%, 30% 80%);
      transform: scale(0.5) rotate(0deg);
    }
    40% {
      clip-path: polygon(60% 0%, 80% 30%, 100% 50%, 70% 80%, 50% 100%, 20% 70%);
      transform: scale(1.8) rotate(120deg); /* Larger scale, partial rotation */
    }
    60% {
      clip-path: polygon(70% 10%, 90% 30%, 100% 50%, 80% 80%, 60% 100%);
      transform: scale(0.7) rotate(240deg); /* Smaller scale, more rotation */
    }
    100% {
      clip-path: polygon(50% 0%, 70% 20%, 90% 40%, 70% 60%, 50% 100%, 30% 80%);
      transform: scale(0.5) rotate(360deg); /* Full rotation */
    }
  }
  
  /* Combined keyframes for Shape 9 */
  @keyframes animateShape9 {
    0% {
      clip-path: polygon(50% 0%, 60% 20%, 90% 50%, 70% 80%, 50% 100%, 20% 80%);
      transform: scale(1.3) rotate(0deg);
    }
    25% {
      clip-path: polygon(70% 20%, 90% 40%, 100% 60%, 70% 80%, 40% 100%);
      transform: scale(1.6) rotate(90deg); /* Larger scale, partial rotation */
    }
    50% {
      clip-path: polygon(50% 10%, 70% 40%, 90% 60%, 70% 90%, 30% 80%);
      transform: scale(1) rotate(180deg); /* Midway scale, half rotation */
    }
    100% {
      clip-path: polygon(50% 0%, 60% 20%, 90% 50%, 70% 80%, 50% 100%, 20% 80%);
      transform: scale(1.3) rotate(360deg); /* Full rotation */
    }
  }
  
  
  /* Unique paths for each shape */
  .path0 {
    animation: animateShape0 30s infinite ease-in-out, moveShape0 100s infinite ease-in-out;
  }
  
  .path1 {
    animation: animateShape1 110s infinite ease-in-out, moveShape1 65s infinite ease-in-out;
  }
  
  .path2 {
    animation: animateShape2 100s infinite ease-in-out, moveShape2 50s infinite ease-in-out;
  }
  
  .path3 {
    animation: animateShape3 80s infinite ease-in-out, moveShape3 80s infinite ease-in-out;
  }
  
  .path4 {
    animation: animateShape4 60s infinite ease-in-out, moveShape4 100s infinite ease-in-out;
  }
  
  .path5 {
    animation: animateShape5 70s infinite ease-in-out, moveShape5 150s infinite ease-in-out;
  }
  .path6 {
    animation: animateShape6 300s infinite ease-in-out, moveShape6 30s infinite ease-in-out;
  }
  .path7 {
    animation: animateShape7 200s infinite ease-in-out, moveShape7 25s infinite ease-in-out;
  }
  .path8 {
    animation: animateShape8 75s infinite ease-in-out, moveShape8 40s infinite ease-in-out;
  }
  .path9 {
    animation: animateShape9 40s infinite ease-in-out, moveShape9 110s infinite ease-in-out;
  }
  
  /* Smooth movements across the screen for each shape */
  @keyframes moveShape0 {
    0% { top: 10%; left: 10%; }
    25% { top: 40%; left: 70%; }
    50% { top: 70%; left: 20%; }
    75% { top: 10%; left: 50%; }
    100% { top: 10%; left: 10%; }
  }
  
  @keyframes moveShape1 {
    0% { top: 20%; left: 80%; }
    25% { top: 60%; left: 30%; }
    50% { top: 90%; left: 70%; }
    75% { top: 30%; left: 10%; }
    100% { top: 20%; left: 80%; }
  }
  
  @keyframes moveShape2 {
    0% { top: 30%; left: 60%; }
    25% { top: 80%; left: 20%; }
    50% { top: 40%; left: 70%; }
    75% { top: 90%; left: 10%; }
    100% { top: 30%; left: 60%; }
  }
  
  @keyframes moveShape3 {
    0% { top: 50%; left: 10%; }
    25% { top: 70%; left: 50%; }
    50% { top: 30%; left: 80%; }
    75% { top: 60%; left: 20%; }
    100% { top: 50%; left: 10%; }
  }
  
  @keyframes moveShape4 {
    0% { top: 60%; left: 40%; }
    25% { top: 40%; left: 20%; }
    50% { top: 80%; left: 30%; }
    75% { top: 50%; left: 90%; }
    100% { top: 60%; left: 40%; }
  }
  
  /* Movement for Shape 5 */
@keyframes moveShape5 {
    0% { top: 30%; left: 30%; }
    25% { top: 50%; left: 60%; }
    50% { top: 70%; left: 40%; }
    75% { top: 10%; left: 20%; }
    100% { top: 30%; left: 30%; }
  }
  
  /* Movement for Shape 6 */
  @keyframes moveShape6 {
    0% { top: 80%; left: 10%; }
    25% { top: 50%; left: 70%; }
    50% { top: 30%; left: 40%; }
    75% { top: 90%; left: 60%; }
    100% { top: 80%; left: 10%; }
  }
  
  /* Movement for Shape 7 */
  @keyframes moveShape7 {
    0% { top: 40%; left: 90%; }
    25% { top: 60%; left: 50%; }
    50% { top: 70%; left: 20%; }
    75% { top: 30%; left: 70%; }
    100% { top: 40%; left: 90%; }
  }
  
  /* Movement for Shape 8 */
  @keyframes moveShape8 {
    0% { top: 10%; left: 40%; }
    25% { top: 80%; left: 30%; }
    50% { top: 50%; left: 80%; }
    75% { top: 40%; left: 20%; }
    100% { top: 10%; left: 40%; }
  }
  
  /* Movement for Shape 9 */
  @keyframes moveShape9 {
    0% { top: 60%; left: 80%; }
    25% { top: 40%; left: 30%; }
    50% { top: 20%; left: 50%; }
    75% { top: 70%; left: 40%; }
    100% { top: 60%; left: 80%; }
  }
  
  /* Button styles */
  .startButton, .stopButton {
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border: 2px solid black;
    border-radius: 5px;
    z-index: 2;
    transition: background-color 0.3s ease;
  }
  
  .startButton:hover, .stopButton:hover {
    background-color: rgba(255, 255, 255, 1);
  }
  
  .stopButton {
    position: absolute;
    top: 50px;
    left: 00px;
  }
  
  .startButton {
    padding: 15px 30px;
    font-size: 18px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.8);
    color: black;
    border: 2px solid black;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Center the button */
    transition: background-color 0.3s ease;
    z-index: 2; /* Ensure it stays on top of the background */
  }

  .message {
    position: absolute;
    top: 200px;
    width: 100%;
    text-align: center;
    font-size: 50px; /* Larger text */
    color: white;
    z-index: 3; /* Ensure it stays on top */
    opacity: 0; /* Hidden initially */
    transition: opacity 2s ease-in-out, transform 2s ease-in-out; /* Smooth transition for opacity and movement */
    transform: translateY(20px); /* Slight movement */
  }
  
  .message.show {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Move up slightly */
  }
  
  .message.hide {
    opacity: 0; /* Fade out */
    transform: translateY(20px); /* Move down slightly for fade out */
  }
  