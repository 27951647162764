.class-list-page {
    padding: 20px;
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .class-list-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .class-list-table th,
  .class-list-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .class-list-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  textarea {
    width: 100%;
    padding: 5px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    resize: vertical;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .edit-button,
  .delete-button,
  .save-button,
  .cancel-button {
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 4px;
  }
  
  .cancel-button {
    background-color: #ffc107;
    color: white;
    border: none;
    border-radius: 4px;
  }
  