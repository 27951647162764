/* General styling for login page only */
body.login-page {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background: linear-gradient(135deg, #5e3b8c 0%, #9b5ea8 50%, #d4a5d9 100%);
    background-size: cover; /* Ensure the gradient covers the screen */
    background-position: center;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Allow overlay elements */
}

/* Foggy Birds Background Overlay for login page */
body.login-page::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./foggy-birds.png'); /* Link to your image */
    background-repeat: repeat; /* Tile the background */
    background-size: auto; /* Ensure the texture is not stretched */
    background-position: center;
    opacity: 0.5; /* Adjust opacity to create a subtle effect */
    z-index: 1; /* Keep it behind other content */
}

/* Password field container similar to Create Account form */
.password-field-container {
    position: relative;
    width: 100%;
}

.password-field-container input {
    width: 60%;
    padding: 12px 16px;
    padding-right: 45px;  /* Ensure space for the eye icon */
    border-radius: 6px;
    border: 1px solid #ccc;
    font-size: 16px;
    box-sizing: border-box;
    color: #333;
    background-color: white;
    margin-bottom: 10px;
}

/* Eye icon inside the password input field */
.show-password-btn {
    position: absolute;
    right: 16px;  /* Position it inside the input field */
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;  /* Slightly larger size for better visibility */
    color: #333;  /* Black icon color for contrast */
    padding: 0;
}

.show-password-btn:hover {
    color: #0056b3;
}

/* Login Container */
.login-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2; /* Ensure it's above the background overlay */
}

/* Login Card */
.login-card {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.8);
    text-align: center;
    z-index: 3; /* Ensure it's above the background overlay */
}

/* Welcome Message Styling */
.login-welcome-message {
    font-size: 1.8rem;
    font-weight: bold;
    color: #5e3b8c;
    margin-bottom: 10px;
    margin-top: 100px;
}

.welcome-subtext {
    font-size: 1.1rem;
    color: #9b5ea8;
    margin-bottom: 30px;
}

/* Input fields */
.input-field input {
    padding: 15px;
    font-size: 1rem;
    border: 2px solid #9b5ea8;
    border-radius: 5px;
    transition: all 0.3s ease;
    width: 100%;
    margin-bottom: 15px; /* Added margin for spacing between fields */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.input-field input:focus {
    outline: none; /* Remove default outline */
    border-color: #602b94; /* Change border color on focus */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Slightly stronger shadow on focus */
}

/* Button styling */
.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}


.login-btn {
    background-color: #5e3b8c;;
    color: white;
    border: none;
    margin: 10px;
}

.login-btn:hover {
    background-color: #5a53e0;
}

.create-btn {
    background-color: #5e3b8c;;
    color: white;
    border: none;
    margin: 10px;
}

.create-btn:hover {
    background-color: #5a53e0;
}


/* Media Query for Mobile Optimization */
@media screen and (max-width: 768px) {

    /* Adjust login card size for tablets and small screens */
    .login-card {
        width: 90%; /* Make the login card width dynamic */
        padding: 30px;
    }

    /* Adjust welcome message font size */
    .login-welcome-message {
        font-size: 1.5rem;
        margin-top: 50px; /* Reduce margin for smaller screens */
    }

    .welcome-subtext {
        font-size: 1rem;
    }

    /* Adjust input fields for mobile */
    .input-field input {
        padding: 12px;
        font-size: 0.9rem;
    }

    /* Adjust button sizes */
    .login-btn,
    .create-btn {
        padding: 10px 15px;
        font-size: 0.9rem;
    }

    /* Stack buttons vertically on small screens */
    .button-group {
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
    }

    .login-btn,
    .create-btn {
        width: 100%; /* Make buttons full-width on small screens */
        margin-bottom: 10px;
    }
}

/* Further optimize for very small screens (e.g., phones) */
@media screen and (max-width: 480px) {

    /* Adjust login card size */
    .login-card {
        width: 95%; /* Even smaller width */
        padding: 20px;
    }

    /* Adjust welcome message and subtext font sizes */
    .login-welcome-message {
        font-size: 1.2rem;
    }

    .welcome-subtext {
        font-size: 0.9rem;
    }

    /* Input field adjustments */
    .input-field input {
        padding: 10px;
        font-size: 0.85rem;
    }

    /* Button adjustments */
    .login-btn,
    .create-btn {
        padding: 8px 12px;
        font-size: 0.85rem;
    }
}