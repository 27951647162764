/* PaymentConfirmationPage.css */
.payment-confirmation-container {
    all: unset; /* Reset all inherited CSS */
    display: block; /* Ensure it's displayed */
    padding: 20px;
    margin: 20px auto;
    text-align: center;
    background-color: white; /* Ensure background is visible */
    color: black; /* Ensure text is visible */
    font-size: 18px; /* Adjust font size for visibility */
    z-index: 1000; /* Ensure it's above other elements */
    position: relative;
  }
  