.logo-container {
    display: flex;
    justify-content: center;  /* Horizontally center the logo */
    align-items: center;      /* Vertically center the logo */
    margin-top: 40px;
    margin-bottom: 45px;
    position: relative;
    z-index: 2;
    padding-top: 20px;
  }
  
  /* Make sure the logo is centered, tilted, and styled */
  .logo {
    max-width: 475px;
    filter: drop-shadow(5px 5px 15px rgba(0, 0, 0, 0.7)); /* Nice shadow */
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* White background for visibility */
    padding: 15px;
    margin: 0 auto; /* Center the logo */
    transform: rotate(-10deg); /* Artistic tilt */
    transition: transform 0.3s ease-in-out; /* Smooth transition on hover */
  }

  