/* General styling */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Arial', sans-serif;
  overflow-x: hidden;
  background-color: #f9f9f9;
}

/* Make sure the home container uses flexbox */
.home-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align from the top */
  align-items: center; /* Center items horizontally */
  width: 100%;

  overflow: hidden;
}

.overlay_mountain {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  align-items: center; /* Horizontally center the content */
}

/* Navigation bar */
.navbar {
  background-color: rgba(0, 0, 0, 0.85); /* Darker transparent background */
  color: white;
  padding: 15px 30px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  backdrop-filter: blur(6px); /* Slight blur for a modern touch */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.nav-links {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-links li {
  display: inline-block;
}

.nav-links a {
  text-decoration: none;
  color: #e0e0e0; /* Soft grey for default link color */
  font-weight: bold;
  font-size: 18px;
  position: relative;
  padding: 10px 15px;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #d4a5d9; /* Purple accent on hover */
}

.active-link {
  border-bottom: 2px solid #d4a5d9; /* Purple accent underline for active link */
}

/* Hero section with background image */
.hero-section {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 65vh; /* Keep the height fixed at 65% of the viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  width: 100vw;
  max-height: 600px; /* Limit max height */
  min-height: 400px; /* Set a minimum height */
  overflow: hidden; /* Prevent content overflow */
}

/* Hero Shadow Box */
.hero-text-box {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7); /* Darker semi-transparent background */
  padding: 30px;
  border-radius: 15px;
  color: white;
  text-align: center;
  font-size: 1.2rem;
  width: 80%;
  max-width: 900px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.7); /* Strong shadow for a 3D effect */
}

/* Text styling */
h1 {
  font-size: 5rem;
  font-weight: bold;
  color: white;
}

p {
  font-size: 2rem;
}

.cta-button {
  background-color: #6c63ff; /* Soft modern purple */
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #5846bf; /* Darker purple on hover */
}

/* Mission Section */
.mission-section {
  padding: 60px 20px;
  text-align: center;
  background: linear-gradient(135deg, rgba(115, 0, 222, 0.8), rgba(172, 72, 232, 0.9)), 
    url('./foggy-birds.png');
  background-attachment: fixed;
  background-blend-mode: overlay;
  color: white;
  border-radius: 15px;
  margin-top: 40px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  width: 100%;
}

.mission-section h2 {
  font-size: 2.2rem;
}

.mission-section p {
  max-width: 800px;
  margin: 0 auto;
}

/* About Section */
.about-section {
  padding: 60px 20px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 15px;
  margin-top: 40px;
  border: 2px solid #9b5ea8;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
}

/* Footer */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px;
  width: 100%;
}

.footer p {
  margin: 0;
  font-size: 14px;
}


/* General styling for logged out CTA */
.cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-image: url('./mountains.jpg'); /* Use the same background as logged-in view */
  background-size: cover;
  background-position: center;
  padding: 40px;
  color: white;
}

.cta-container h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 01); /* Add a nice shadow to make text pop */
}

.cta-container p {
  font-size: 1.5rem;
  max-width: 800px;
  margin: 0 auto 40px;
  text-shadow: 4px 4px 10px rgba(0, 0, 0, 1);
}

.discover-container {
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
  color: white;
  padding: 50px;
  border-radius: 25px;
  width: 70rem;
  margin: 50px auto; /* Added more margin for a balanced appearance */
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5); /* Larger shadow for a more prominent effect */
  z-index: 2; /* Ensure it is above the background */
  text-align: center;
}
.cta-buttons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.cta-buttons button {
  padding: 15px 40px;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button {
  background-color: #6c63ff; /* Soft modern purple */
  color: white;
}

.login-button:hover {
  background-color: #5846bf; /* Darker purple on hover */
}

.signup-button {
  background-color: #9b5ea8; /* Lighter purple for contrast */
  color: white;
}

.signup-button:hover {
  background-color: #804a91; /* Slightly darker on hover */
}

/* Hero overlay with shadow box */
.overlay {
  position: relative;
  background-color: rgba(0, 0, 0, 0.6); /* Dark semi-transparent background */
  padding: 50px;
  border-radius: 15px;
  max-width: 80%;
  margin: 0 auto;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.3); /* Soft shadow for depth */
  z-index: 1; /* Ensures it sits above the background image */
  text-align: center;
}

.home-h1{
  color: white;
  font-size: 3rem;
  text-decoration: underline;
}

.greeting {
  color: #b1a7e9;
  font-size: 4rem;
  text-decoration: underline;

}

/* Base styles (for larger screens) will remain as is */

/* General responsive text handling */
@media (max-width: 768px) {
  h1 {
      font-size: 6vw; /* Scale down font size for smaller screens */
      line-height: 1.2;
      word-wrap: break-word;
      overflow-wrap: break-word;
  }

  h2 {
      font-size: 4vw;
      line-height: 1.2;
  }

  p {
      font-size: 3vw;
      line-height: 1.5;
  }

  .cta-button {
      font-size: 1rem;
      padding: 10px 20px;
      width: 100%;
  }

  .nav-links {
      flex-direction: column;
      width: 100%;
  }
}
/* For even smaller devices (like phones in portrait) */
@media screen and (max-width: 480px) {

  /* Reduce font sizes for better fit on small screens */
  h1 {
    font-size: 2rem; /* Scale down further */
    line-height: 1.2; /* Increase line height for better readability */
    word-wrap: break-word; /* Ensure long words wrap properly */
  }

  p {
    font-size: 0.9rem; /* Reduce font size slightly */
    line-height: 1.4; /* Increase line height for better readability */
    word-wrap: break-word; /* Ensure long words wrap properly */
  }
  
  .cta-button {
    padding: 8px 15px;
    font-size: 0.85rem; /* Slightly reduce button text size */
    width: 100%; /* Make buttons full-width on small screens */
  }

  .hero-section {
    height: 35vh; /* Reduce hero section height for better fit */
    padding: 10px; /* Reduce padding to save space */
  }

  .hero-text-box {
    font-size: 0.85rem; /* Reduce text size for hero section */
    padding: 10px 15px; /* Smaller padding for better layout */
    line-height: 1.3; /* Increase line height for readability */
  }

  .cta-container h1 {
    font-size: 1.6rem; /* Further reduce heading size */
    line-height: 1.3; /* Increase line height */
    word-wrap: break-word; /* Ensure text wraps properly */
  }

  .cta-container p {
    font-size: 0.9rem; /* Reduce paragraph font size */
    line-height: 1.5; /* Increase line height for better readability */
  }
  
  .cta-buttons {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center; /* Center buttons in the container */
    width: 100%; /* Make button container full-width */
  }

  .discover-container {
    width: 100%; /* Use full width of the screen */
    padding: 10px 15px; /* Reduce padding to make more room */
    word-wrap: break-word; /* Ensure text wraps and doesn’t overflow */
  }
}