/* Styling for the Enrolled Classes Page */
.enrolled-classes-container {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white;
    padding: 50px;
    border-radius: 25px;
    width: 70rem;
    margin: 50px auto; /* Added more margin for a balanced appearance */
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5); /* Larger shadow for a more prominent effect */
    z-index: 2; /* Ensure it is above the background */
    text-align: center;
}

/* Header Styling */
.enrolled-classes-container h1 {
    font-size: 3rem; /* Larger font for the title */
    margin-bottom: 30px;
    color: #f5f5f5; /* Brighter color for better contrast */
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Added text shadow */
}

/* Error message styling */
.error-message {
    color: red;
    font-size: 1.5rem;
    margin-bottom: 30px;
}

/* List of Enrolled Classes */
.enrolled-classes-list {
    list-style: none;
    padding: 0;
    margin: 40px 0;
}

.enrolled-classes-list li {
    background-color: #e0e0e0;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.15);
    margin-bottom: 30px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.enrolled-classes-list li:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.2);
}

.enrolled-classes-list h2 {
    font-size: 2rem;
    color: #602b94; /* Keep consistent branding */
    margin-bottom: 15px;
}
.enrolled-classes-list h3 {
    font-size: 2.5rem;
    color: #602b94; /* Keep consistent branding */
    margin-bottom: 15px;
    text-decoration: underline;
}
.enrolled-classes-list p {
    font-size: 1.4rem;
    margin-bottom: 15px;
    color: #333;
}

/* Disenroll Button */
.disenroll-button {
    padding: 15px 30px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.4rem;
    transition: background-color 0.3s ease;
}

.disenroll-button:hover {
    background-color: #5846bf;
}

/* Not enrolled message */
.not-enrolled-message {
    font-size: 1.8rem;
    margin-top: 50px;
    color: #602b94;
}

/* Styling for background and logo */
.offerings-background {
    background-color: #7300de;
    background-image: url('./foggy-birds.png'); /* Replace with your background texture */
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat;
    background-size: cover; /* Ensures the background texture covers the screen */
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}