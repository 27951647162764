/* Custom CSS for waiver Page */
.waiver-background {
    background-color: #7300de;
    background-image: url('./foggy-birds.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat;
    background-size: auto;
    background-blend-mode: overlay;
    min-height: 100vh;
    display: flex;
    flex-direction: column; /* Stack the logo and content vertically */
    justify-content: flex-start; /* Align items from the top */
    align-items: center; /* Center items horizontally */
    padding: 20px;
}
/* Container for the entire waiver form */
.waiver-form-container {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white;
    padding: 30px;
    border-radius: 15px;
    margin: auto;
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
    z-index: 2; /* Ensure it is above the background */
}

  
  /* Style for the form's title */
  .waiver-form-container h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #f0e68c;
  }
  
  /* Style the input fields */
  .waiver-form-container input {
    display: block;
    width: 100%;
    padding: 12px;
    margin: 15px 0;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 1.1rem;
  }
 
  .waiver-text-container {
    height: 500px;  /* Height of the waiver text container */
    width: 800px;   /* Width of the waiver text container */
    overflow-y: scroll;  /* Enable vertical scrolling */
    border: 1px solid #ccc;  /* Light gray border around the container */
    padding: 10px;  /* Padding inside the container */
    margin-bottom: 20px;  /* Space below the container */
  }
  
  .waiver-text-container pre {
    white-space: pre-wrap;  /* Preserve whitespace and new lines */
    text-align: left;  /* Align the text to the left */
    font-size: 20px;  /* Increase the font size */
    line-height: 1.6;  /* Adjust line height for better readability */
  }

  /* Style the signature area */
  .signature-area {
    margin: 20px 0;
    border: 2px dashed #ddd;
    padding: 20px;
    border-radius: 10px;
    background-color: whitesmoke;
  }
  
  /* Buttons styling */
  button {
    background-color: #602b94;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    margin-top: 20px;
    transition: background-color 0.3s ease-in-out;
  }
  
  button:hover {
    background-color: #3a195c;
  }
  
  /* Clear button for signature */
  .clear-button {
    background-color: #f54242;
  }
  
  button.clear-button:hover {
    background-color: #c13030;
  }
  
  .thank-you-container {
    text-align: center;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8); /* Add a translucent white background */
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    max-width: 600px;
    margin: 50px auto; /* Center the container */
    color: #4a2d8e; /* Set a nice color for the text */
  }
  
  .thank-you-container h1 {
    font-size: 2rem;
    color: #602b94; /* Purple tone for the header */
  }