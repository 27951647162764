/* General styling for the purchase tokens container */
.purchase-tokens-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, rgba(115, 0, 222, 0.8), rgba(172, 72, 232, 0.9)), 
                url('./foggy-birds.png');
    background-attachment: fixed;
    background-blend-mode: overlay;
    text-align: center;
    padding: 40px;
    color: white;
}

/* Logged out view styling */
.logged-out-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #5e3b8c 0%, #9b5ea8 50%, #d4a5d9 100%);
    background-blend-mode: overlay;
    text-align: center;
    padding: 40px;
    color: white;
}

.logged-out-container h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.logged-out-container p {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.logged-out-actions {
    display: flex;
    gap: 20px;
}

.btn {
    padding: 12px 24px;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #5a53e0;
}

.create-btn {
    background-color: #e0e0e0;
    color: #5e3b8c;
    border: none;
}

.create-btn:hover {
    background-color: #d4d4d4;
}

/* Purchase section styling */
.purchase-section {
    background-color: rgba(24, 18, 26, 0.8);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 500px;
    width: 100%;
}

.purchase-section h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    color: #f0e6f6;
}

.purchase-section label {
    font-size: 1.2rem;
    display: block;
    margin-bottom: 10px;
}

.purchase-section input {
    width: 100px;
    padding: 10px;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 15px;
}

.purchase-section p {
    font-size: 1.1rem;
    margin-bottom: 20px;
}

/* Purchase button styling */
.purchase-btn {
    padding: 12px 24px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 15px;
}

.purchase-btn:hover {
    background-color: #5a53e0;
}

/* Back to Offerings button */
.back-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #e0e0e0;
    color: #5e3b8c;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
}

.back-btn:hover {
    background-color: #d4d4d4;
}

.token-options {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-bottom: 20px;
}

.token-option-btn {
    padding: 10px 20px;
    background-color: #602b94;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.token-option-btn.active {
    background-color: #3a195c;
}

.token-option-btn:hover {
    background-color: #3a195c;
}