/* General page styling */
.create-account-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  font-family: 'Arial', sans-serif;
  min-height: 100vh;
}

.create-account-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('./foggy-birds.png'), linear-gradient(135deg, #5e3b8c 0%, #9b5ea8 50%, #d4a5d9 100%);
  background-position: center;
  width: 100%;
  height: 110%;
}

.create-account-container {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  text-align: center;
}

.account-title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.welcome-message {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 30px;
  line-height: 1.5;
}

.alert-danger {
  color: #d9534f;
  margin-bottom: 20px;
}

/* Input field styling */
.ca-input-field {
  width: 100%;
  padding: 12px 16px;  /* Increase padding for a better appearance */
  margin-bottom: 20px; /* Add margin between input fields */
  border-radius: 6px;  /* Slightly round the corners */
  border: 1px solid #ccc;  /* Subtle border for the input */
  font-size: 16px;  /* Increase font size for readability */
  box-sizing: border-box;  /* Ensure padding and width behave as expected */
  color: #333;  /* Darker text color for better readability */
  background-color: white;  /* Ensure background is white */
  transition: border-color 0.3s ease-in-out;  /* Smooth transition on focus */
}

.ca-input-field:focus {
  border-color: #007bff;  /* Blue border on focus */
  outline: none;  /* Remove the default outline */
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.25);  /* Add a subtle shadow */
}

/* Styling for the password field container */
.password-field-container {
  position: relative;
  width: 100%;
}

/* Password input field */
.password-field-container input {
  width: 100%;
  padding: 12px 16px;  /* Match padding with other inputs */
  padding-right: 45px;  /* Ensure space for the eye icon */
  border-radius: 6px;
  border: 1px solid #ccc;
  font-size: 16px;
  box-sizing: border-box;
  color: #333;
  background-color: white;
}

/* Eye icon styling */
.show-password-btn {
  position: absolute;
  right: 16px;  /* Position closer to the right edge */
  top: 50%;
  transform: translateY(-100%);  /* Vertically center the icon */
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5rem;  /* Slightly larger size for better visibility */
  color: #333;  /* Black icon color for contrast */
  padding: 0;
}

/* Button styling for form submission */
.create-btn {
  background-color: #6c63ff;  /* Purple button color */
  color: white;
  padding: 15px 20px;  /* More padding for larger button */
  font-size: 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  width: 100%;  /* Full width of the container */
  transition: background-color 0.3s ease;
}

.create-btn:hover {
  background-color: #5846bf;  /* Darker purple on hover */
}

/* Already logged in container */
.already-logged-in-container {
  background-color: white;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  margin-top: 20px;
  text-align: center;
}

.already-logged-in-container h1 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.already-logged-in-container p {
  font-size: 1.1rem;
  color: #555;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .create-account-container {
    width: 100%;
  }

  .account-title {
    font-size: 1.5rem;
  }

  .welcome-message {
    font-size: 1rem;
  }

  .create-btn {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .create-account-container {
    width: 100%;
  }

  .logo {
    max-width: 150px;
  }

  .account-title {
    font-size: 1.3rem;
  }

  .welcome-message {
    font-size: 0.9rem;
  }

  .create-btn {
    font-size: 14px;
    padding: 10px;
  }
}
