/* Transition for showing and hiding class list and class details */
.class-list, .class-details {
    transition: opacity 0.5s ease-in-out, height 0.5s ease-in-out;
}

/* When hidden, reduce opacity to 0 and set height to 0 */
.class-list.hidden, .class-details.hidden {
    opacity: 0;
    height: 0;
    overflow: hidden;
}

/* Offerings Background */
.offerings-background {
    background-color: #7300de;
    background-image: url('./foggy-birds.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: repeat;
    background-size: auto;
    background-blend-mode: overlay;
    min-height: 100vh;
    display: flex;
    flex-direction: column; /* Stack the logo and content vertically */
    justify-content: flex-start; /* Align items from the top */
    align-items: center; /* Center items horizontally */
    padding: 20px;
}

/* Custom CSS for Offerings Page */
.offerings-container {
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    color: white;
    padding: 50px;
    border-radius: 25px;
    width: 70rem;
    margin: 50px auto; /* Added more margin for a balanced appearance */
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.5); /* Larger shadow for a more prominent effect */
    z-index: 2; /* Ensure it is above the background */
    text-align: center;
}

/* Display tokens and purchase button */
.token-display {
    background-color: #e0e0e0;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 12px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #602b94;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buy-tokens-btn {
    padding: 10px 20px;
    background-color: #602b94;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.buy-tokens-btn:hover {
    background-color: #3a195c;
}

/* Class List Styling */
.class-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
}

.class-item {
    background-color: #e0e0e0;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s ease;
}

.class-item:hover {
    transform: translateY(-10px);
}

.class-item h1 {
    font-size: 2.2rem;
    color: #5e3b8c;
    text-decoration: underline;
}
.class-item h2 {
    font-size: 1.8rem;
    color: #5e3b8c;
}

.class-item p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #666;
}

.class-item button {
    padding: 10px 20px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.class-item button:hover {
    background-color: #5846bf;
}

/* Class Details */
.class-details {
    margin-top: 30px;
    padding: 30px;
    background-color: #e0e0e0;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.class-details h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    color: #602b94;
}

.class-details p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #666;
}

.class-details button {
    padding: 12px 24px;
    background-color: #6c63ff;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.class-details button:hover {
    background-color: #5846bf;
}

/* Not Logged In View */
.logged-out-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, rgba(94, 59, 140, 0.8), rgba(155, 94, 168, 0.8), rgba(212, 165, 217, 0.8)), 
                url('./foggy-birds.png');
    background-repeat: repeat; /* Tile the background */
    background-size: auto; /* Ensure the texture is not stretched */
    background-blend-mode: overlay; /* This allows the gradient and the texture to blend */
    text-align: center;
    padding: 20px;
    color: white; /* Ensures text stands out on the gradient */
}

.logged-out-container .logo {
    width: 200px;
    margin-bottom: 30px;
}

.logged-out-container h1 {
    font-size: 2.5rem;
    color: #5e3b8c;
    margin-bottom: 10px;
}

.logged-out-container p {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 30px;
}

.logged-out-actions {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.logged-out-actions .btn {
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.logged-out-actions .login-btn {
    background-color: #9b5ea8;
    color: white;
}

.logged-out-actions .login-btn:hover {
    background-color: #5e3b8c;
}

.logged-out-actions .create-btn {
    background-color: #6c63ff;
    color: white;
}

.logged-out-actions .create-btn:hover {
    background-color: #5846bf;
}

@media (max-width: 768px) {
    .navbar {
        padding: 10px 20px;
    }
    
    .hero-section {
        height: 40vh;
        width: 30vh; /* Further reduce hero height for smaller screens */
    }

    .hero-text-box {
        padding: 15px;
        font-size: 1rem;
        width: 90%;
    }

    .offerings-container {
        width: 95%; /* Ensure content takes up more space */
    }

    .token-display {
        flex-direction: column; /* Stack items on mobile */
    }

    .cta-button {
        padding: 10px 15px;
        font-size: 1rem;
    }

    .footer {
        padding: 15px;
    }
}
